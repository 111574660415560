<template>
    <div class="container-fluid">
    <div class="row">
            <div class="col-md-4">
                <usuario-acceso-login 
                :usuario="usuario"
                :id="id"/>
            </div>
            <div  v-if="(id > 0)" class="col-md-8">
                <usuario-acceso-seguridad 
                :usuario="usuario"
            />
            </div>
        </div>
    <div class="row">
         <div  v-if="(id > 0)" class="col-md-6">
             <usuario-datos-basico
             :usuario="usuario"
             :id="usuario.id" />
         </div>
         <div  v-if="(id > 0)" class="col-md-6">
             <usuario-datos-laboral
             :usuario="usuario"
             :id="usuario.id"
             />
         </div>
     </div>
    </div>
</template>
<script>
import UsuarioDatosBasico from './secciones/UsuarioDatosBasico.vue'
import UsuarioDatosLaboral from './secciones/UsuarioDatosLaboral.vue'
import UsuarioAccesoLogin from '../acceso/secciones/UsuarioAccesoLogin.vue'
import UsuarioAccesoSeguridad from '../acceso/secciones/UsuarioAccesoSeguridad.vue'
export default {
    props: ['usuario','id'],
    data() {
        return {
        }
    },

    components: {
        'usuario-datos-basico': UsuarioDatosBasico,
        'usuario-datos-laboral': UsuarioDatosLaboral,
        'usuario-acceso-login': UsuarioAccesoLogin,
        'usuario-acceso-seguridad': UsuarioAccesoSeguridad,
    },
    
    activated() {
    },
}
</script>

